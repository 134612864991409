:export {
  mainFont: Favorit;
  mainFontLining: FavoritLining;
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

.home {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.proceedToCart {
  font-family: "FavoritLining", sans-serif;
  font-size: 24px;
  background-color: transparent;
  border: none;
  padding: 0px;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
  text-decoration: none;
  color: #000;
  display: inline-block;
  text-align: center;
  padding: 32px 0 64px 0;
  font-size: 32px;
}

