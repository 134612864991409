@import '../../../theme/main.scss';

.sentence {
    overflow: hidden;
    display: block;
    white-space: nowrap;
}

.word {
    display: inline-block;
    transform: translateY(102%);
    transition: transform 0.25s ease-in;

    &.active {
        transform: translateY(0%);
        transition: transform 0.25s ease-out;
    }
}
