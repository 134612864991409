@import '../../../theme/main.scss';

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 64px $DEFAULT_PADDING/2 $DEFAULT_PADDING/2 $DEFAULT_PADDING/2;
    box-sizing: border-box;

    @media #{$defaultBreakpoint} {
        flex-direction: row;
        align-items: flex-start;
        padding: $DEFAULT_PADDING;
    }
}

.photoCarousel {
    margin-bottom: 32px;
    
    @media #{$defaultBreakpoint} {
        margin-right: 56px;
        margin-bottom: 0px;
    }
}

.data {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media #{$defaultBreakpoint} {
        width: 485px;
    }
}

.headerMobile {
    margin-bottom: 31px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;

    @media #{$defaultBreakpoint} {
        display: none;
    }

    .title {
        text-transform: uppercase;
        font-size: 32px;
        line-height: 38px;
        font-family: $mainFontLining;
        font-weight: $medium;
        flex: 2;
    }
    
    .moreInfo {
        font-size: 16px;
        font-family: $mainFontLining;
        font-weight: $medium;
        cursor: pointer;
        background-color: transparent;
        border: none;
        padding: 0;
        flex: 1;
        text-align: right;
    }
}

.titleDesktop {
    text-transform: uppercase;
    font-size: 32px;
    font-family: $mainFontLining;
    margin-bottom: 15px;
    display: none;

    @media #{$defaultBreakpoint} {
        display: block;
    }
}

.descriptionMobile {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 32px;
    color: $black;
    font-weight: $light;

    @media #{$defaultBreakpoint} {
        display: none;
    }
}

.descriptionDesktop {
    font-size: 24px;
    margin-bottom: 24px;
    color: $black;
    font-weight: $light;
    display: none;

    @media #{$defaultBreakpoint} {
        display: block;
    }
}

.itemPrice {
    font-size: 24px;
    margin-bottom: 24px;
    color: $black;
    font-weight: $bold;
}

.parameterTitle {
    font-size: 24px;
    font-family: $mainFontLining;
    height: 41px;
    line-height: 24px;
    color: $black;
}

.quantityFieldMobile {
    margin-bottom: 32px;
    width: 100%;
    line-height: 43px;
    padding: 0 16px 13px 16px;

    @media #{$defaultBreakpoint} {
        display: none;
    }
}

.quantityFieldDesktop {
    margin-bottom: 28px;
    width: 264px;
    height: 64px;
    display: none;

    @media #{$defaultBreakpoint} {
        display: flex;
    }
}

.variationSelector {
    margin-bottom: 30px;
    width: 100%;

    @media #{$iphone} {
        line-height: 43px;
        padding: 0 16px 13px 16px;
    }

    @media #{$defaultBreakpoint} {
        width: 264px;
    }
}

.addButton, .soldOut {
    @include button();
    width: 100%;
    margin-bottom: 48px;

    @media #{$defaultBreakpoint} {
        width: 264px;
    }
}

.soldOut {
    color: $black;
    background-color: $white;
    border: 2px solid $black;
    text-transform: uppercase;
    text-align: center;
    cursor: auto;
}

.proceedLink, .backToStore {
    font-family: $mainFontLining;
    font-size: 24px;
    margin-bottom: 16px;
    color: $black;
}

.proceedLink {
    display: none;

    @media #{$defaultBreakpoint} {
        display: block;
    }
}
