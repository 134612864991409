@import '../../../theme/main.scss';

.collection {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    z-index: $modalZ;
    background-color: $black;
    padding: 0;
    display: block;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 65px 0 $DEFAULT_PADDING/2 0;
    box-sizing: border-box;
    min-height: 100%;
    
    @media #{$defaultBreakpoint} {
        min-height: 0%;
        height: 100%;
        padding: 0;
    }
}

.info {
    flex: 1;
    color: $white;
    box-sizing: border-box;
    padding: 0 $DEFAULT_PADDING/2;

    @media #{$defaultBreakpoint} {
        padding: 0 0 0 $DEFAULT_PADDING;
    }
}

.title {
    font-family: $mainFontLining;
    font-weight: $medium;
    font-size: 32px;
    margin-bottom: 29px;
    text-transform: uppercase;
    
    @media #{$defaultBreakpoint} {
        margin-bottom: 15px;
    }
}

.description {
    box-sizing: border-box;
    font-size: 24px;
    line-height: 28px;
    font-weight: $light;
    
    p {
        margin: 0 0 24px 0;
    }

    @media #{$defaultBreakpoint} {
        padding-right: 40%;
    }
}

.roulette {
    height: 100%;
    flex: 1;
    display: none;

    @media #{$defaultBreakpoint} {
        display: block;
    }
}

.carousel {
    width: 100%;
    height: 100%;
}
