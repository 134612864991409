@import '../../../theme/main.scss';

.cartList, .title {
    max-width: 100%;
    width: 580px;
    margin: 0px auto;
    box-sizing: border-box;
}

.cartList {
    padding: 0 $DEFAULT_PADDING/2 34px $DEFAULT_PADDING/2;

    @media #{$defaultBreakpoint} {
        padding: 0 $DEFAULT_PADDING $DEFAULT_PADDING $DEFAULT_PADDING;
    }
}

.title {
    text-align: left;
    font-weight: $medium;
    color: $black;
    font-family: $mainFontLining;
    text-transform: uppercase;
    font-size: 32px;
    padding: 130px $DEFAULT_PADDING/2 $DEFAULT_PADDING $DEFAULT_PADDING/2;

    @media #{$defaultBreakpoint} {
        padding: 0 $DEFAULT_PADDING $DEFAULT_PADDING $DEFAULT_PADDING;
        font-size: 24px;
        margin-bottom: 48px;
    }
}

.warning {
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
    text-align: left;
    width: 100%;
    margin-bottom: 32px;
}

.submit {
    font-family: $mainFontLining;
    font-size: 24px;
    background-color: transparent;
    border: none;
    padding: 0px;
    cursor: pointer;
    text-transform: uppercase;
    width: 100%;
}

.loadingSpinner {
    width: 100px;
    display: block;
    margin: 0px auto;
}

.totalPrice {
    font-size: 24px;
    font-style: italic;
    margin-bottom: 32px;
}

.homeButton {
    @include textButton();
    text-align: left;
    text-transform: none;
    margin-bottom: 32px;
}

.checkoutButton {
    @include button();
    width: 100%;

    @media #{$defaultBreakpoint} {
        width: 260px;
    }
}
