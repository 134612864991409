:export {
  mainFont: Favorit;
  mainFontLining: FavoritLining;
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

.carousel {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
  padding-top: 50px;
}

@media (min-width: 1025px) {
  .carousel {
    padding-top: 0;
  }
}

.storyTimer {
  padding: 32px 16px;
  height: 4px;
  background-color: #000;
}

.carouselPhotos {
  flex: 1;
  position: relative;
  overflow: visible;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  transition: transform 0.75s ease-in-out;
}

.carouselPhoto {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-shrink: 0;
  flex-grow: 1;
}

.leftArrow,
.rightArrow {
  width: 43px;
  height: 80px;
  background-image: url(./arrowright.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  mix-blend-mode: difference;
  display: none;
}

@media (min-width: 1025px) {
  .leftArrow,
  .rightArrow {
    display: block;
  }
}

.leftArrow {
  transform: translateY(-50%) scale(-0.5, 0.5);
  left: 16px;
}

@media (min-width: 1025px) {
  .leftArrow {
    transform: translateY(-50%) scaleX(-1);
    left: 32px;
  }
}

.rightArrow {
  transform: translateY(-50%) scale(0.5, 0.5);
  right: 16px;
}

@media (min-width: 1025px) {
  .rightArrow {
    transform: translateY(-50%);
    right: 32px;
  }
}

