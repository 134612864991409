@import '../../../theme/main.scss';

.footer {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    background-color: $black;
    z-index: $footerZ;
    padding: 60px 0 $DEFAULT_PADDING 0;
    display: flex;
    flex-direction: column;
    
    @media #{$ipadOrDesktop} {
        padding: 0 0 $DEFAULT_PADDING 0;
        align-items: center;
    }
}

.slogan {
    position: static;

    @media #{$defaultBreakpoint} {
        transform: scale(2);
    }
}

.menu {
    position: static;
    margin-top: 5px;
}

.infoGrid {
    width: 100%;
    display: grid;
    box-sizing: border-box;
    padding: 0 $DEFAULT_PADDING;
    grid-template-rows: auto auto auto auto auto;
    grid-template-columns: 1fr;
    grid-template-areas:
        'sp'
        'business'
        'press'
        'media'
        'social'
        'copyright'
    ;
    height: 100%;
    align-items: end;
    
    @media #{$defaultBreakpoint} {
        align-items: start;
        flex: 1;
        height: auto;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-areas:
            '.         .        .      .    '
            'sp        business press  media'
            '.         .        .      .    '
            'copyright .        social .    '
        ;
        grid-column-gap: 10px;
    }

    .sp {
        grid-area: sp;
    }

    .business {
        grid-area: business;
    }

    .media {
        grid-area: media;
    }

    .press {
        grid-area: press;
    }

    .copyright {
        grid-area: copyright;

        @media #{$defaultBreakpoint} {
            align-self: end;
        }
    }

    .social {
        grid-area: social;

        @media #{$defaultBreakpoint} {
            align-self: end;
        }
    }
}

.infoBlock {
    display: block;

    .title {
        color: $white;
        font-size: 20px;
        line-height: 32px;
    }
    
    .content {
        color: $darkGrey;
        font-weight: $light;
        font-size: 20px;
        cursor: pointer;
        text-decoration: none;
        font-family: $mainFont;
    }

    &:hover {
        .content {
            font-family: $mainFontLining;
        }
    }
}

.copyright {
    color: $white;
    font-size: 20px;
    white-space: nowrap;
    display: none;

    @media #{$defaultBreakpoint} {
        display: block;
    }
}

.social {
    a {
        color: $white;
        font-size: 20px;
        font-family: $mainFontLining;
        text-decoration: none;
        margin-right: 30px;
    }
}
