:export {
  mainFont: Favorit;
  mainFontLining: FavoritLining;
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

.checkoutPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  z-index: 30;
  background-color: #fff;
  padding: 130px 16px 34px 16px;
}

@media (min-width: 1025px) {
  .checkoutPage {
    padding: 0px 32px;
    justify-content: center;
    align-items: center;
  }
}

.title {
  text-align: left;
  font-weight: 500;
  color: #000;
  font-family: "FavoritLining", sans-serif;
  text-transform: uppercase;
  font-size: 32px;
  margin-bottom: 32px;
}

@media (min-width: 1025px) {
  .title {
    font-size: 24px;
    margin-bottom: 48px;
  }
}

.content,
.title {
  max-width: 100%;
  width: 580px;
}

