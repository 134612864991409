@import '../../../theme/main.scss';

.menuView {
    width: 100%;
    height: 100%;
    background-color: $black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: 100%;
    transform: translateY(0%);
    transition: transform 0.5s ease-in;
    z-index: $menuViewZ;

    &.open {
        transform: translateY(100%);
        transition: transform 0.5s ease-out;
    }

    @media #{$defaultBreakpoint} {
        display: none;
    }
}

.menuItem {
    font-family: $mainFontLining;
    font-size: 32px;
    margin: 24px 0;
    text-transform: uppercase;
    color: $white;
    text-decoration: none;
    background-color: transparent;
    border: none;
}

.closeButton {
    position: absolute;
    right: proportionHeight(100);
    top: proportionHeight(100);
}
