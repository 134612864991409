:export {
  mainFont: Favorit;
  mainFontLining: FavoritLining;
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

.header {
  z-index: 10;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dojoTitle {
  position: relative;
  width: 100%;
  height: 54px;
  background-position: center;
  background-repeat: repeat-x;
  background-size: auto 100%;
  transition: background-position 0.5s ease-out;
  background-image: url(/static/images/header/store.png);
}

@media (min-width: 1025px) {
  .dojoTitle {
    height: 225px;
  }

  .dojoTitle:hover {
    background-image: url(/static/images/header/storeOutline.png);
  }
}

.image {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 72vw;
}

@media (min-width: 1025px) {
  .image {
    max-width: auto;
    width: 37.5vw;
  }
}

.collectionLink {
  position: relative;
  font-size: 32px;
  font-weight: 500;
  margin-top: 93px;
  color: #000;
  text-transform: uppercase;
  display: none;
}

@media (min-width: 1025px) {
  .collectionLink {
    display: block;
  }
}

