@import '../../../theme/main.scss';

.header {
    z-index: $homeZ;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dojoTitle {
    position: relative;
    width: 100%;
    height: 54px;
    background-position: center;
    background-repeat: repeat-x;
    background-size: auto 100%;
    transition: background-position 0.5s ease-out;
    background-image: url(/static/images/header/store.png);
    
    @media #{$defaultBreakpoint} {
        height: 225px;
        
        &:hover {
            background-image: url(/static/images/header/storeOutline.png);
        }
    }
}

.image {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 72vw;
    
    @media #{$defaultBreakpoint} {
        max-width: auto;
        width: 37.5vw;
    }
}

.collectionLink {
    position: relative;
    font-size: 32px;
    font-weight: $medium;
    margin-top: 93px;
    color: $black;
    text-transform: uppercase;
    display: none;

    @media #{$defaultBreakpoint} {
        display: block;
    }
}
