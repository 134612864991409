:export {
  mainFont: Favorit;
  mainFontLining: FavoritLining;
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

.quantitySelector {
  position: relative;
  overflow: visible;
  box-sizing: border-box;
  border: 2px solid #000;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  font-family: "FavoritLining", sans-serif;
  color: #000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.buttons {
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 32px;
}

.buttons button {
  font-family: "FavoritLining", sans-serif;
  font-size: 24px;
  background-color: transparent;
  border: none;
  padding: 0px;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
  text-decoration: none;
  color: #000;
  display: inline-block;
  text-align: center;
  font-family: "Favorit", sans-serif;
  width: 56px;
}

