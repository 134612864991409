@import '../../../theme/main.scss';

.carousel {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
    padding-top: 50px;

    @media #{$defaultBreakpoint} {
        padding-top: 0;
    }
}

.storyTimer {
    padding: $DEFAULT_PADDING $DEFAULT_PADDING/2;
    height: 4px;
    background-color: $black;
}

.carouselPhotos {
    flex: 1;
    position: relative;
    overflow: visible;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    transition: transform 0.75s ease-in-out;
}

.carouselPhoto {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    flex-shrink: 0;
    flex-grow: 1;
}

.leftArrow, .rightArrow {
    width: 43px;
    height: 80px;
    background-image: url(./arrowright.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    mix-blend-mode: difference;
    display: none;

    @media #{$defaultBreakpoint} {
        display: block;
    }    
}

.leftArrow {
    transform: translateY(-50%) scale(-0.5, 0.5);
    left: 16px;

    @media #{$defaultBreakpoint} {
        transform: translateY(-50%) scaleX(-1);
        left: 32px;
    }
}

.rightArrow {
    transform: translateY(-50%) scale(0.5, 0.5);
    right: 16px;

    @media #{$defaultBreakpoint} {
        transform: translateY(-50%);
        right: 32px;
    }
}

