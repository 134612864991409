@import '../../../theme/main.scss';

.cartItem {
    display: flex;
    flex-direction: row;
    height: 170px;
    align-items: stretch;
    margin-bottom: 32px;

    img {
        height: 100%;
    }
}

.description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 17px 28px 0 28px;
    box-sizing: border-box;

    @media #{$defaultBreakpoint} {
        flex: 0.6;
    }
}

.itemName {
    font-weight: $bold;
    font-size: 16px;
    font-family: $mainFontLining;
}

.info {
    font-size: 16px;
}

.removeButton {
    display: inline;
    background-color: transparent;
    border: none;
    color: $red;
    font-size: 16px;
    font-weight: $medium;
    cursor: pointer;
    padding: 0;

    &.disabled {
        cursor: auto;
        visibility: hidden;
        pointer-events: none;
    }
}
