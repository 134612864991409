:export {
  mainFont: Favorit;
  mainFontLining: FavoritLining;
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

:export {
  mainFont: Favorit;
  mainFontLining: FavoritLining;
}

@font-face {
  font-family: 'FavoritLining';
  src: url(/static/fonts/Favorit-RegularLining.woff);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'FavoritLining';
  src: url(/static/fonts/Favorit-LightLining.woff);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Favorit';
  src: url(/static/fonts/Favorit-Medium.woff);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Favorit';
  src: url(/static/fonts/Favorit-Light.woff);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Favorit';
  src: url(/static/fonts/Favorit-Regular.woff);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Favorit';
  src: url(/static/fonts/Favorit-Bold.woff);
  font-weight: 700;
  font-style: normal;
}

html,
body {
  margin: 0px;
  padding: 0px;
  border: 0px;
  font-family: "Favorit", sans-serif;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

a {
  text-decoration: none;
  font-family: "FavoritLining", sans-serif;
}

a:hover {
  text-decoration: none;
}

input[type=text],
input[type=password],
input[type=number],
option {
  border: 2px solid #000;
  box-sizing: border-box;
  font-size: 24px;
  padding: 7px;
}

select {
  font-family: "FavoritLining", sans-serif;
  font-size: 24px;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 0;
  background-color: transparent;
  border: 2px solid #000;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(./arrow.svg);
  background-repeat: no-repeat;
  background-size: auto 20%;
  background-position: calc(100% - 16px) 50%;
}

*:focus {
  outline: none;
}

.app {
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.app.locked {
  overflow-y: hidden;
}

.store {
  font-family: "FavoritLining", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  mix-blend-mode: difference;
  position: fixed;
  left: 32px;
  top: 76.8px;
  pointer-events: none;
  z-index: 40;
  text-transform: uppercase;
}

.slogan {
  position: fixed;
  left: 32px;
  top: 32px;
  z-index: 40;
  cursor: pointer;
  display: none;
}

@media (min-width: 1025px) {
  .slogan {
    display: block;
  }
}

