@import '../../../theme/main.scss';

.spinner {
    margin: 50px auto;
}

.itemsList {
    box-sizing: border-box;
    padding: $DEFAULT_PADDING $DEFAULT_PADDING/2 $DEFAULT_PADDING/2 $DEFAULT_PADDING/2;
    position: relative;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 56px;

    @media #{$ipad} {
        grid-template-columns: repeat(2, 1fr);
        padding: (3 * $DEFAULT_PADDING) $DEFAULT_PADDING $DEFAULT_PADDING $DEFAULT_PADDING;
    }

    @media #{$desktop} {
        grid-template-columns: repeat(4, 1fr);
    }
}
