@import '../../../theme/main.scss';

.dojo {
    @include inverse();
    transform-origin: 0 0;
}

.letter {
    height: 23px;
    margin-right: 23px;
}

.children {
    color: $white;
    margin-top: 17px;
    font-size: 16px;
    font-weight: $medium;
    text-transform: uppercase;
}
