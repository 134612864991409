:export {
  mainFont: Favorit;
  mainFontLining: FavoritLining;
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

.cartItem {
  display: flex;
  flex-direction: row;
  height: 170px;
  align-items: stretch;
  margin-bottom: 32px;
}

.cartItem img {
  height: 100%;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 17px 28px 0 28px;
  box-sizing: border-box;
}

@media (min-width: 1025px) {
  .description {
    flex: 0.6;
  }
}

.itemName {
  font-weight: 700;
  font-size: 16px;
  font-family: "FavoritLining", sans-serif;
}

.info {
  font-size: 16px;
}

.removeButton {
  display: inline;
  background-color: transparent;
  border: none;
  color: #ff0000;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  padding: 0;
}

.removeButton.disabled {
  cursor: auto;
  visibility: hidden;
  pointer-events: none;
}

