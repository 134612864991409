@import '../../../theme/main.scss';

.cartCount {
    margin-left: 10px;
    display: inline-block;
    mix-blend-mode: difference;
}

.count {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: $white;
    color: $black;
    font-size: 16px;
    font-family: $mainFont;
    font-weight: $bold;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 3px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}
