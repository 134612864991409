:export {
  mainFont: Favorit;
  mainFontLining: FavoritLining;
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

.error,
.warning {
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
}

.error {
  color: #ff0000;
}

.loadingSpinner {
  width: 100px;
  display: block;
  margin: 0px auto;
}

.formFieldTitle {
  font-size: 24px;
  font-family: "FavoritLining", sans-serif;
  font-weight: 500;
  margin-bottom: 8px;
}

.reviewButton {
  font-family: "FavoritLining", sans-serif;
  font-size: 24px;
  background-color: transparent;
  border: none;
  padding: 0px;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
  text-decoration: none;
  color: #000;
  display: inline-block;
  text-align: center;
  text-align: left;
  text-transform: none;
  font-weight: 500;
  margin: 16px 0 32px 0;
}

.submit {
  font-size: 24px;
  height: 28px;
  font-family: "FavoritLining", sans-serif;
  padding: 16px 0px;
  width: 260px;
  text-align: center;
  color: #fff;
  background-color: #000;
  display: inline-block;
  cursor: pointer;
  border: none;
  width: 100%;
}

@media (min-width: 1025px) {
  .submit {
    width: 260px;
  }
}

.soldOutItem {
  margin-bottom: 43px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.soldOutItem .itemInfo .name {
  font-size: 16px;
  font-family: "FavoritLining", sans-serif;
  margin-bottom: 13px;
  font-weight: 500;
}

.soldOutItem .itemInfo .quantity {
  font-size: 16px;
  font-weight: 300;
}

.soldOutItem .deliveryTime {
  font-size: 16px;
  font-weight: 300;
  text-align: right;
}

.buttons {
  margin-top: 70px;
}

@media (min-width: 1025px) {
  .buttons {
    white-space: nowrap;
  }
}

.finishPurchase,
.reviewOrder {
  font-size: 24px;
  height: 28px;
  font-family: "FavoritLining", sans-serif;
  padding: 16px 0px;
  width: 260px;
  text-align: center;
  color: #fff;
  background-color: #000;
  display: inline-block;
  cursor: pointer;
  border: none;
  width: 100%;
  margin-bottom: 32px;
}

@media (min-width: 1025px) {
  .finishPurchase,
  .reviewOrder {
    width: auto;
    padding: 16px 48px;
  }
}

@media (min-width: 1025px) {
  .finishPurchase {
    margin-right: 32px;
  }
}

