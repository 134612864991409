:export {
  mainFont: Favorit;
  mainFontLining: FavoritLining;
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

.hamburgerButton {
  border: none;
  background-color: transparent;
  width: 38px;
  height: 34px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  cursor: pointer;
  box-sizing: border-box;
  padding: 12px 9px;
  overflow: visible;
}

.hamburgerButton .line {
  width: 100%;
  height: 2px;
  transform-origin: 0px 50%;
  transition: transform 0.25s ease-in-out;
  background-color: #fff;
}

.hamburgerButton.close .line:nth-child(1) {
  transform: translateY(-4px) rotate(45deg) scaleX(1.12);
}

.hamburgerButton.close .line:nth-child(2) {
  transform: translateY(4px) rotate(-45deg) scaleX(1.12);
}

