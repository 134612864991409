@import '../../theme/main.scss';
@import '../../theme/fontFaces.scss';

html, body {
    margin: 0px;
    padding: 0px;
    border: 0px;
    font-family: $mainFont;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

a {
    text-decoration: none;
    font-family: $mainFontLining;
    
    &:hover {
        text-decoration: none;
    }
}

input[type=text], input[type=password], input[type=number], option {
    border: 2px solid $black;
    box-sizing: border-box;
    font-size: 24px;
    padding: 7px;
}

select {
    font-family: $mainFontLining;
    font-size: 24px;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 0;
    background-color: transparent;
    border: 2px solid #000;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(./arrow.svg);
    background-repeat: no-repeat;
    background-size: auto 20%;
    background-position: calc(100% - 16px) 50%;
}

*:focus {
    outline: none;
}

.app {
    background-color: $white;
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    &.locked {
        overflow-y: hidden;
    }
}

.store {
    font-family: $mainFontLining;
    font-size: 16px;
    font-weight: $light;
    color: $white;
    mix-blend-mode: difference;
    position: fixed;
    left: $DEFAULT_PADDING;
    top: 2.4 * $DEFAULT_PADDING;
    pointer-events: none;
    z-index: $sloganZ;
    text-transform: uppercase;
}

.slogan {
    position: fixed;
    left: $DEFAULT_PADDING;
    top: $DEFAULT_PADDING;
    z-index: $sloganZ;
    cursor: pointer;
    display: none;

    @media #{$defaultBreakpoint} {
        display: block;
    }
}
