:export {
  mainFont: Favorit;
  mainFontLining: FavoritLining;
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

.footer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  background-color: #000;
  z-index: 20;
  padding: 60px 0 32px 0;
  display: flex;
  flex-direction: column;
}

@media (min-width: 415px) {
  .footer {
    padding: 0 0 32px 0;
    align-items: center;
  }
}

.slogan {
  position: static;
}

@media (min-width: 1025px) {
  .slogan {
    transform: scale(2);
  }
}

.menu {
  position: static;
  margin-top: 5px;
}

.infoGrid {
  width: 100%;
  display: grid;
  box-sizing: border-box;
  padding: 0 32px;
  grid-template-rows: auto auto auto auto auto;
  grid-template-columns: 1fr;
  grid-template-areas: 'sp' 'business' 'press' 'media' 'social' 'copyright';
  height: 100%;
  align-items: end;
}

@media (min-width: 1025px) {
  .infoGrid {
    align-items: start;
    flex: 1;
    height: auto;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: '.         .        .      .    ' 'sp        business press  media' '.         .        .      .    ' 'copyright .        social .    ';
    grid-column-gap: 10px;
  }
}

.infoGrid .sp {
  grid-area: sp;
}

.infoGrid .business {
  grid-area: business;
}

.infoGrid .media {
  grid-area: media;
}

.infoGrid .press {
  grid-area: press;
}

.infoGrid .copyright {
  grid-area: copyright;
}

@media (min-width: 1025px) {
  .infoGrid .copyright {
    align-self: end;
  }
}

.infoGrid .social {
  grid-area: social;
}

@media (min-width: 1025px) {
  .infoGrid .social {
    align-self: end;
  }
}

.infoBlock {
  display: block;
}

.infoBlock .title {
  color: #fff;
  font-size: 20px;
  line-height: 32px;
}

.infoBlock .content {
  color: #6d6d6d;
  font-weight: 300;
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
  font-family: "Favorit", sans-serif;
}

.infoBlock:hover .content {
  font-family: "FavoritLining", sans-serif;
}

.copyright {
  color: #fff;
  font-size: 20px;
  white-space: nowrap;
  display: none;
}

@media (min-width: 1025px) {
  .copyright {
    display: block;
  }
}

.social a {
  color: #fff;
  font-size: 20px;
  font-family: "FavoritLining", sans-serif;
  text-decoration: none;
  margin-right: 30px;
}

