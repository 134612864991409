@import '../../../theme/main.scss';

.checkoutPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    z-index: $modalZ;
    background-color: #fff;
    padding: 130px $DEFAULT_PADDING/2 34px $DEFAULT_PADDING/2;

    @media #{$defaultBreakpoint} {
        padding: 0px $DEFAULT_PADDING;
        justify-content: center;
        align-items: center;
    }
}

.title {
    text-align: left;
    font-weight: $medium;
    color: $black;
    font-family: $mainFontLining;
    text-transform: uppercase;
    font-size: 32px;
    margin-bottom: 32px;
    
    @media #{$defaultBreakpoint} {
        font-size: 24px;
        margin-bottom: 48px;
    }
}

.content, .title {
    max-width: 100%;
    width: 580px;
}
