:export {
  mainFont: Favorit;
  mainFontLining: FavoritLining;
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

.cartList,
.title {
  max-width: 100%;
  width: 580px;
  margin: 0px auto;
  box-sizing: border-box;
}

.cartList {
  padding: 0 16px 34px 16px;
}

@media (min-width: 1025px) {
  .cartList {
    padding: 0 32px 32px 32px;
  }
}

.title {
  text-align: left;
  font-weight: 500;
  color: #000;
  font-family: "FavoritLining", sans-serif;
  text-transform: uppercase;
  font-size: 32px;
  padding: 130px 16px 32px 16px;
}

@media (min-width: 1025px) {
  .title {
    padding: 0 32px 32px 32px;
    font-size: 24px;
    margin-bottom: 48px;
  }
}

.warning {
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  text-align: left;
  width: 100%;
  margin-bottom: 32px;
}

.submit {
  font-family: "FavoritLining", sans-serif;
  font-size: 24px;
  background-color: transparent;
  border: none;
  padding: 0px;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
}

.loadingSpinner {
  width: 100px;
  display: block;
  margin: 0px auto;
}

.totalPrice {
  font-size: 24px;
  font-style: italic;
  margin-bottom: 32px;
}

.homeButton {
  font-family: "FavoritLining", sans-serif;
  font-size: 24px;
  background-color: transparent;
  border: none;
  padding: 0px;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
  text-decoration: none;
  color: #000;
  display: inline-block;
  text-align: center;
  text-align: left;
  text-transform: none;
  margin-bottom: 32px;
}

.checkoutButton {
  font-size: 24px;
  height: 28px;
  font-family: "FavoritLining", sans-serif;
  padding: 16px 0px;
  width: 260px;
  text-align: center;
  color: #fff;
  background-color: #000;
  display: inline-block;
  cursor: pointer;
  border: none;
  width: 100%;
}

@media (min-width: 1025px) {
  .checkoutButton {
    width: 260px;
  }
}

