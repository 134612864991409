@import '../../../theme/main.scss';

.storyTimer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 2px;
}

.storyBar {
    display: flex;
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    padding: 0px 5px;
}

.storyContainer {
    background-color: $darkGrey;
    width: 100%;
    height: 100%;
}

.storyProgress {
    background-color: $grey;
    width: 0px;
    height: 100%;
}
