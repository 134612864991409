@import '../../../theme/main.scss';

.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    z-index: $modalZ;
    background-color: #fff;

    @media #{$defaultBreakpoint} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
    }
}

.content {
    height: 100%;
    overflow: hidden auto;
    // padding: 130px $DEFAULT_PADDING/2 34px $DEFAULT_PADDING/2;

    @media #{$defaultBreakpoint} {
        height: auto;
        max-height: 100%;
        // padding: $DEFAULT_PADDING;
    }
}
