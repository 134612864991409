@import '../../../theme/main.scss';

.photoCarousel {
    width: 100%;
    
    @media #{$defaultBreakpoint} {
        width: 739px;
    }
}

.photo {
    width: 100%;
    height: 384px;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    
    @media #{$defaultBreakpoint} {
        height: 592px;
    }
}

.thumbnails {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 8px;
    width: 100%;
    overflow-x: auto;

    @media #{$defaultBreakpoint} {
        margin-top: 16px;
    }

    button {
        flex-shrink: 0;
        width: 71px;
        margin-right: 8px;
        display: block;
        cursor: pointer;
        border: none;
        background-color: transparent;
        padding: 0px;

        @media #{$defaultBreakpoint} {
            width: 166px;
        }

        img {
            width: 100%;
        }

        &:last-child {
            margin-right: 0px;
        }
    }
}
