:export {
  mainFont: Favorit;
  mainFontLining: FavoritLining;
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

.collection {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  z-index: 30;
  background-color: #000;
  padding: 0;
  display: block;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 65px 0 16px 0;
  box-sizing: border-box;
  min-height: 100%;
}

@media (min-width: 1025px) {
  .content {
    min-height: 0%;
    height: 100%;
    padding: 0;
  }
}

.info {
  flex: 1;
  color: #fff;
  box-sizing: border-box;
  padding: 0 16px;
}

@media (min-width: 1025px) {
  .info {
    padding: 0 0 0 32px;
  }
}

.title {
  font-family: "FavoritLining", sans-serif;
  font-weight: 500;
  font-size: 32px;
  margin-bottom: 29px;
  text-transform: uppercase;
}

@media (min-width: 1025px) {
  .title {
    margin-bottom: 15px;
  }
}

.description {
  box-sizing: border-box;
  font-size: 24px;
  line-height: 28px;
  font-weight: 300;
}

.description p {
  margin: 0 0 24px 0;
}

@media (min-width: 1025px) {
  .description {
    padding-right: 40%;
  }
}

.roulette {
  height: 100%;
  flex: 1;
  display: none;
}

@media (min-width: 1025px) {
  .roulette {
    display: block;
  }
}

.carousel {
  width: 100%;
  height: 100%;
}

