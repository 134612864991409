@import './fonts.scss';

@font-face {
    font-family: 'FavoritLining';
    src: url(/static/fonts/Favorit-RegularLining.woff);
    font-weight: $regular;
    font-style: normal;
}

@font-face {
    font-family: 'FavoritLining';
    src: url(/static/fonts/Favorit-LightLining.woff);
    font-weight: $light;
    font-style: normal;
}

@font-face {
    font-family: 'Favorit';
    src: url(/static/fonts/Favorit-Medium.woff);
    font-weight: $medium;
    font-style: normal;
}

@font-face {
    font-family: 'Favorit';
    src: url(/static/fonts/Favorit-Light.woff);
    font-weight: $light;
    font-style: normal;
}

@font-face {
    font-family: 'Favorit';
    src: url(/static/fonts/Favorit-Regular.woff);
    font-weight: $regular;
    font-style: normal;
}

@font-face {
    font-family: 'Favorit';
    src: url(/static/fonts/Favorit-Bold.woff);
    font-weight: $bold;
    font-style: normal;
}
