@import '../../../theme/main.scss';

.quantitySelector {
    position: relative;
    overflow: visible;
    box-sizing: border-box;
    border: 2px solid $black; 
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    font-family: $mainFontLining;
    color: $black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.buttons {
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 32px;

    button {
        @include textButton();
        font-family: $mainFont;
        width: 56px;
    }
}
