@import '../../../theme/main.scss';

.ideogram {
    position: fixed;
    left: $DEFAULT_PADDING/2;
    top: $DEFAULT_PADDING/2;
    height: 33px;
    width: 41px;
    background-image: url(./ideogramIcon.png);
    background-size: contain;
    background-repeat: no-repeat;
    z-index: $ideogramZ;
    mix-blend-mode: difference;

    @media #{$defaultBreakpoint} {
        left: auto;
        top: auto;
        right: $DEFAULT_PADDING;
        bottom: $DEFAULT_PADDING;
        height: 25px;
        width: 30px;
    }

    @media #{$retina} {
        background-image: url(./ideogramIcon@2x.png);
    }
}
