@import '../../../theme/main.scss';

.purchaseResult {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-family: $mainFontLining;
    font-size: 32px;
    text-align: center;
}
