@import '../../../theme/main.scss';

.hamburgerButton {
    border: none;
    background-color: transparent;
    width: 38px;
    height: 34px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    cursor: pointer;
    box-sizing: border-box;
    padding: 12px 9px;
    overflow: visible;

    .line {
        width: 100%;
        height: 2px;
        transform-origin: 0px 50%;
        transition: transform 0.25s ease-in-out;
        background-color: $white;
    }

    &.close {
        .line:nth-child(1) {
            transform: translateY(-4px) rotate(45deg) scaleX(1.12);
        }

        .line:nth-child(2) {
            transform: translateY(4px) rotate(-45deg) scaleX(1.12);
        }
    }
}
