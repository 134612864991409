:export {
  mainFont: Favorit;
  mainFontLining: FavoritLining;
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

.storyTimer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2px;
}

.storyBar {
  display: flex;
  flex: 1;
  height: 100%;
  box-sizing: border-box;
  padding: 0px 5px;
}

.storyContainer {
  background-color: #6d6d6d;
  width: 100%;
  height: 100%;
}

.storyProgress {
  background-color: #b9b9b9;
  width: 0px;
  height: 100%;
}

