@import '../../../theme/main.scss';

.menu {
    @include inverse();
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    right: $DEFAULT_PADDING/2;
    top: $DEFAULT_PADDING/2;
    z-index: $menuZ;
    box-sizing: border-box;

    @media #{$defaultBreakpoint} {
        right: $DEFAULT_PADDING;
        top: $DEFAULT_PADDING;
    }
}

.link {
    color: $white;
    margin-left: 56px;
    font-family: $mainFontLining;
    font-weight: $medium;
    font-size: 16px;
    text-transform: uppercase;
    display: none;

    @media #{$defaultBreakpoint} {
        display: inline-block;
    }
}

.hamburgerButton {
    margin-left: 23px;

    @media #{$defaultBreakpoint} {
        display: none;
    }
}
