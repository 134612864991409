@import '../../../theme/main.scss';

.item {
    width: 100%;
    position: relative;
    overflow: hidden;

    a, img {
        width: 100%;
        height: auto;
        flex-shrink: 0;
        display: block;
    }

    a {
        font-family: $mainFont;
        color: $white;
        text-decoration: none;
    }
}

.header {
    color: $black;
}

.type, .name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.type {
    line-height: 16px;
    font-size: 16px;
    font-weight: $light;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.name {
    font-size: 24px;
    font-weight: $medium;
    height: 37px;
    font-family: $mainFontLining;
}

.itemLink {
    position: relative;

    .price {
        position: absolute;
        right: 10px;
        top: 10px;
    }
}