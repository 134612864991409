:export {
  mainFont: Favorit;
  mainFontLining: FavoritLining;
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

.ideogram {
  position: fixed;
  left: 16px;
  top: 16px;
  height: 33px;
  width: 41px;
  background-image: url(./ideogramIcon.png);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 70;
  mix-blend-mode: difference;
}

@media (min-width: 1025px) {
  .ideogram {
    left: auto;
    top: auto;
    right: 32px;
    bottom: 32px;
    height: 25px;
    width: 30px;
  }
}

@media (min-resolution: 2dppx) {
  .ideogram {
    background-image: url(./ideogramIcon@2x.png);
  }
}

