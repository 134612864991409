@import '../../../theme/main.scss';

.error, .warning {
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
}

.error {
    color: $red;
}

.loadingSpinner {
    width: 100px;
    display: block;
    margin: 0px auto;
}

.formFieldTitle {
    font-size: 24px;
    font-family: $mainFontLining;
    font-weight: $medium;
    margin-bottom: 8px;
}

.reviewButton {
    @include textButton();
    text-align: left;
    text-transform: none;
    font-weight: $medium;
    margin: 16px 0 32px 0;
}

.submit {
    @include button();
    width: 100%;

    @media #{$defaultBreakpoint} {
        width: 260px;
    }
}

.soldOutItem {
    margin-bottom: 43px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .itemInfo {
        .name {
            font-size: 16px;
            font-family: $mainFontLining;
            margin-bottom: 13px;
            font-weight: $medium;
        }
        
        .quantity {
            font-size: 16px;
            font-weight: $light;
        }
    }

    .deliveryTime {
        font-size: 16px;
        font-weight: $light;
        text-align: right;
    }
}

.buttons {
    margin-top: 70px;

    @media #{$defaultBreakpoint} {
        white-space: nowrap;
    }
}

.finishPurchase, .reviewOrder {
    @include button();
    width: 100%;
    margin-bottom: 32px;

    @media #{$defaultBreakpoint} {
        width: auto;
        padding: 16px 48px; 
    }
}

.finishPurchase {
    @media #{$defaultBreakpoint} {
        margin-right: 32px;
    }
}
