:export {
  mainFont: Favorit;
  mainFontLining: FavoritLining;
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 64px 16px 16px 16px;
  box-sizing: border-box;
}

@media (min-width: 1025px) {
  .content {
    flex-direction: row;
    align-items: flex-start;
    padding: 32px;
  }
}

.photoCarousel {
  margin-bottom: 32px;
}

@media (min-width: 1025px) {
  .photoCarousel {
    margin-right: 56px;
    margin-bottom: 0px;
  }
}

.data {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1025px) {
  .data {
    width: 485px;
  }
}

.headerMobile {
  margin-bottom: 31px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
}

@media (min-width: 1025px) {
  .headerMobile {
    display: none;
  }
}

.headerMobile .title {
  text-transform: uppercase;
  font-size: 32px;
  line-height: 38px;
  font-family: "FavoritLining", sans-serif;
  font-weight: 500;
  flex: 2;
}

.headerMobile .moreInfo {
  font-size: 16px;
  font-family: "FavoritLining", sans-serif;
  font-weight: 500;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  flex: 1;
  text-align: right;
}

.titleDesktop {
  text-transform: uppercase;
  font-size: 32px;
  font-family: "FavoritLining", sans-serif;
  margin-bottom: 15px;
  display: none;
}

@media (min-width: 1025px) {
  .titleDesktop {
    display: block;
  }
}

.descriptionMobile {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 32px;
  color: #000;
  font-weight: 300;
}

@media (min-width: 1025px) {
  .descriptionMobile {
    display: none;
  }
}

.descriptionDesktop {
  font-size: 24px;
  margin-bottom: 24px;
  color: #000;
  font-weight: 300;
  display: none;
}

@media (min-width: 1025px) {
  .descriptionDesktop {
    display: block;
  }
}

.itemPrice {
  font-size: 24px;
  margin-bottom: 24px;
  color: #000;
  font-weight: 700;
}

.parameterTitle {
  font-size: 24px;
  font-family: "FavoritLining", sans-serif;
  height: 41px;
  line-height: 24px;
  color: #000;
}

.quantityFieldMobile {
  margin-bottom: 32px;
  width: 100%;
  line-height: 43px;
  padding: 0 16px 13px 16px;
}

@media (min-width: 1025px) {
  .quantityFieldMobile {
    display: none;
  }
}

.quantityFieldDesktop {
  margin-bottom: 28px;
  width: 264px;
  height: 64px;
  display: none;
}

@media (min-width: 1025px) {
  .quantityFieldDesktop {
    display: flex;
  }
}

.variationSelector {
  margin-bottom: 30px;
  width: 100%;
}

@media (max-width: 414px) {
  .variationSelector {
    line-height: 43px;
    padding: 0 16px 13px 16px;
  }
}

@media (min-width: 1025px) {
  .variationSelector {
    width: 264px;
  }
}

.addButton,
.soldOut {
  font-size: 24px;
  height: 28px;
  font-family: "FavoritLining", sans-serif;
  padding: 16px 0px;
  width: 260px;
  text-align: center;
  color: #fff;
  background-color: #000;
  display: inline-block;
  cursor: pointer;
  border: none;
  width: 100%;
  margin-bottom: 48px;
}

@media (min-width: 1025px) {
  .addButton,
  .soldOut {
    width: 264px;
  }
}

.soldOut {
  color: #000;
  background-color: #fff;
  border: 2px solid #000;
  text-transform: uppercase;
  text-align: center;
  cursor: auto;
}

.proceedLink,
.backToStore {
  font-family: "FavoritLining", sans-serif;
  font-size: 24px;
  margin-bottom: 16px;
  color: #000;
}

.proceedLink {
  display: none;
}

@media (min-width: 1025px) {
  .proceedLink {
    display: block;
  }
}

