:export {
  mainFont: Favorit;
  mainFontLining: FavoritLining;
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

.item {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.item a,
.item img {
  width: 100%;
  height: auto;
  flex-shrink: 0;
  display: block;
}

.item a {
  font-family: "Favorit", sans-serif;
  color: #fff;
  text-decoration: none;
}

.header {
  color: #000;
}

.type,
.name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.type {
  line-height: 16px;
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.name {
  font-size: 24px;
  font-weight: 500;
  height: 37px;
  font-family: "FavoritLining", sans-serif;
}

.itemLink {
  position: relative;
}

.itemLink .price {
  position: absolute;
  right: 10px;
  top: 10px;
}

