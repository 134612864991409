@import '../../../theme/main.scss';

.home {
    width: 100%;
    box-sizing: border-box;
    position: relative;
}

.proceedToCart {
    @include textButton();
    padding: $DEFAULT_PADDING 0 (2 * $DEFAULT_PADDING) 0;
    font-size: 32px;
}
