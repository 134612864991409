:export {
  mainFont: Favorit;
  mainFontLining: FavoritLining;
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

.menuView {
  width: 100%;
  height: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 100%;
  transform: translateY(0%);
  transition: transform 0.5s ease-in;
  z-index: 50;
}

.menuView.open {
  transform: translateY(100%);
  transition: transform 0.5s ease-out;
}

@media (min-width: 1025px) {
  .menuView {
    display: none;
  }
}

.menuItem {
  font-family: "FavoritLining", sans-serif;
  font-size: 32px;
  margin: 24px 0;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  background-color: transparent;
  border: none;
}

.closeButton {
  position: absolute;
  right: proportionHeight(100);
  top: proportionHeight(100);
}

