:export {
  mainFont: Favorit;
  mainFontLining: FavoritLining;
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

.photoCarousel {
  width: 100%;
}

@media (min-width: 1025px) {
  .photoCarousel {
    width: 739px;
  }
}

.photo {
  width: 100%;
  height: 384px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 1025px) {
  .photo {
    height: 592px;
  }
}

.thumbnails {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 8px;
  width: 100%;
  overflow-x: auto;
}

@media (min-width: 1025px) {
  .thumbnails {
    margin-top: 16px;
  }
}

.thumbnails button {
  flex-shrink: 0;
  width: 71px;
  margin-right: 8px;
  display: block;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0px;
}

@media (min-width: 1025px) {
  .thumbnails button {
    width: 166px;
  }
}

.thumbnails button img {
  width: 100%;
}

.thumbnails button:last-child {
  margin-right: 0px;
}

