:export {
  mainFont: Favorit;
  mainFontLining: FavoritLining;
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

.spinner {
  margin: 50px auto;
}

.itemsList {
  box-sizing: border-box;
  padding: 32px 16px 16px 16px;
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  grid-gap: 56px;
}

@media (min-width: 415px) and (max-width: 1024px) {
  .itemsList {
    grid-template-columns: repeat(2, 1fr);
    padding: 96px 32px 32px 32px;
  }
}

@media (min-width: 1025px) {
  .itemsList {
    grid-template-columns: repeat(4, 1fr);
  }
}

