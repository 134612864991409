:export {
  mainFont: Favorit;
  mainFontLining: FavoritLining;
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

.menu {
  mix-blend-mode: difference;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  right: 16px;
  top: 16px;
  z-index: 60;
  box-sizing: border-box;
}

@media (min-width: 1025px) {
  .menu {
    right: 32px;
    top: 32px;
  }
}

.link {
  color: #fff;
  margin-left: 56px;
  font-family: "FavoritLining", sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  display: none;
}

@media (min-width: 1025px) {
  .link {
    display: inline-block;
  }
}

.hamburgerButton {
  margin-left: 23px;
}

@media (min-width: 1025px) {
  .hamburgerButton {
    display: none;
  }
}

