@import './breakpoints.scss';

@mixin view() {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    overflow-x: hidden;
}

@mixin retinaBg($image, $extension: jpg) {
    background-image: url($image + '.' + $extension);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
         
    @media (min-resolution: 2dppx) {    
       background-image: url($image + '@2x' + '.' + $extension);
    }
}

@mixin textStroke($ts) {
    -webkit-text-stroke: $ts;
            text-stroke: $ts;
}

@mixin button() {
    font-size: 24px;
    height: 28px;
    font-family: $mainFontLining;
    padding: 16px 0px;
    width: 260px;
    text-align: center;
    color: $white;
    background-color: $black;
    display: inline-block;
    cursor: pointer;
    border: none;
}

@mixin textButton() {
    font-family: $mainFontLining;
    font-size: 24px;
    background-color: transparent;
    border: none;
    padding: 0px;
    cursor: pointer;
    text-transform: uppercase;
    width: 100%;
    text-decoration: none;
    color: $black;
    display: inline-block;
    text-align: center;
}

@mixin inverse() {
    mix-blend-mode: difference;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
